import { styled } from '@mui/system'
import { Skeleton, Grid } from '@mui/material'

const shouldForwardProp = (prop) => prop !== 'columnGap' && prop !== 'orientation'

const GridStyled = styled(Grid, { shouldForwardProp })`
	${({ orientation }) =>
		orientation === 'horizontal' ? 'display: flex; align-items: flex-start;' : null}/* padding: ${({ columnGap }) =>
		columnGap ? columnGap + 'px' : '0'}; */
	/* width: ${({ columns }) => (columns ? 'calc(100%/' + columns + ')' : '100%')}; */
`

const Wrapper = styled('div')`
	${({ orientation }) => (orientation === 'horizontal' ? 'flex: 2;' : null)}
	margin-right:20px;
`

const SkeletonCardStyled = styled(Skeleton)`
	margin-bottom: 10px;
	${({ orientation }) => (orientation === 'horizontal' ? 'flex: 1; margin-bottom: 0' : null)}
`

const SkeletonStyled = styled(Skeleton)`
	margin-bottom: 10px;
	transform: scale(1, 1);
`

export { GridStyled, SkeletonCardStyled, SkeletonStyled, Wrapper }
